import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, PlayButton } from './item.css';
import { Icon } from 'react-icons-kit';
import { ic_play_arrow, ic_pause } from 'react-icons-kit/md';

const ItemButton = ({
  title,
  image,
  className,
  showButton,
  isPlaying,
  onClick,
}) => (
  <figure className={className}>
    <Img
      className="fluidimage"
      fluid={image ? image.childImageSharp.fluid : {}}
      alt={title}
    />
    {!showButton || (
      <PlayButton onClick={onClick}>
        <Icon size={160} icon={!isPlaying ? ic_play_arrow : ic_pause} />
      </PlayButton>
    )}
    <figcaption>
      <Title>{title}</Title>
      item.js
    </figcaption>
  </figure>
);

ItemButton.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default ItemButton;
