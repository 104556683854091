import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Head from 'components/head';
import Productions from 'components/productions';
import find from 'lodash/find';

const ProductionTemplate = ({ data, pageContext }) => {
  const getItemContent = (podcasts, slugKey) => {
    return find(podcasts, { slug: slugKey });
  };
  const { slug } = pageContext;
  return (
    <>
      <Head pageTitle={data.productionsJson.title} />
      <Productions data={getItemContent(data.productionsJson.podcasts, slug)} />
    </>
  );
};

ProductionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductionTemplate;

export const query = graphql`
  query templateQuery {
    productionsJson {
      title
      podCastHeader
      podcasts {
        title
        slug
        copy
        longCopy {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        imageSocial {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        status
        link
        color
        colorText
        background
        episodes {
          title
          copy {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          image {
            childImageSharp {
              fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageSocial {
            childImageSharp {
              fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          releaseDate
          references
          status
          URI
        }
      }
    }
  }
`;
