import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Header = styled.div`
  display: block;
  width: ${({ takeover }) => () => {
    switch (takeover) {
      case true:
        return '100%';
      default:
        return 'auto';
    }
  }};
  background-color: ${({ takeover, backColor }) => () => {
    switch (takeover) {
      case true:
        return backColor;
      default:
        return 'none';
    }
  }};
  background-image: ${({ background }) => () => {
    if (background) {
      return background;
    }
    return 'none';
  }};

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const ImageTrailer = styled.div`
  display: flex;
  margin: 4rem 0 0;
  padding: 4rem;
  color: #333;
  width: 400px;
  background-color: transparent;
  line-height: 1.2;
  margin: 0 auto;
  width: 80vw;

  ${MEDIA.PHONE`
      width: 100%;
          display: flex;
    flex-direction: column;
  `};

  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;

export const ArtWork = styled.div`
  display: inline-block;

  line-height: 1.2;
  & .podcast-hero {
    padding: 0;
    width: 500px;
    height: 500px;

    ${MEDIA.LARGE`
    width: 30vw;
    height: 30vw;
  `};

    ${MEDIA.PHONE`
    width: 80vw;
    height: 80vw;
    margin: 0;
  `};

    figcaption {
      display: none;
    }
  }
`;

export const EpisodeArt = styled.img`
  display: inline-block;
  width: 15vw;
  height: 15vw;
  background-color: #666666;
  border: 0 none;
`;

export const Aside = styled.div`
  display: inline-block;
  font-size: 1.25rem;
  order: 9;
  top: -15px;
  position: relative;

  ${MEDIA.PHONE`
    position: unset;
  `};

  transition: all 500ms ease-out;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    & .foldsout {
      text-decoration: none !important;
    }
  }
  .foldsout {
    max-height: 0;
    overflow: hidden;
    color: #ccc;
    transition: all 500ms ease-out;
  }
  &.active {
    &:hover {
      text-decoration: none !important;
    }

    .foldsout {
      max-height: unset;
      text-decoration: none;
      color: #666;
      overflow: visible;
    }
  }
`;

export const Titles = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;

  color: ${({ colorText }) => () => {
    if (colorText) {
      return colorText;
    }
    return '333333';
  }};

  ${MEDIA.PHONE`
        margin:0;
        max-width: 80vw;
  `};
  h2 {
    font-weight: 700;
    font-size: 2rem;

    ${MEDIA.PHONE`
        display:none;
  `};
  }
  h3 {
    margin: 1rem 0;
    font-size: 4rem;
  }
  h4 {
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: 700;
  }
  p {
    max-width: 500px;
  }

  em {
    font-style: italic;
  }
  a {
    margin-right: 10px;
    img {
      width: 150px;
    }
  }
  .podcast-platforms {
    margin-top: auto;
  }

  time {
    font-weight: 700;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #666;

    display: none;
  }
`;

export const EpisodeList = styled.ul`
  display: flex;
  width: 80vw;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  margin: 0 auto;
  padding-top: 5rem;

  ${MEDIA.TABLET`
      width: 80vw;
      
  `};

  ${MEDIA.PHONE`
      width: 100vw;
      
  `};

  h3 {
    margin: 1rem 0;
    font-size: 3vw;
  }
  a {
    margin-right: 10px;
    img {
      width: 150px;
    }
  }
  .podcast-item__about {
    & p {
      font-size: 2rem;
    }
    font-size: 1.25rem;
    .podcast-sponsor {
      width: 55px;
      vertical-align: text-top;
    }

    .clickLink {
      margin: 12px 0;
      font-weight: 600;

      & ~ dd {
        display: none;
      }
    }
    ul {
      display: inline-flex;
      height: 90px;
      li {
        margin: 0 20px 0 0;
        em {
          font-style: italic;
          padding: 0 0 10px 0;
          display: inline-block;
        }
      }
    }
  }
  .podcast-hero--list {
    overflow: unset;
    position: relative;
    width: 200px;
    height: 200px;
    padding: 0;
    ${MEDIA.DESKTOP`
     width: 175px;
          height: 175px;
    `};

    ${MEDIA.TABLET`
         width: 175px;
    `};

    ${MEDIA.PHONE`
         width: 175px;
    `};

    figcaption {
      display: none;
    }
  }
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 200px;
  height: 200px;
  opacity: 0.7;
  transition: 0.2s background-color ease;
  background-color: transparent;
  :hover {
    background-color: #0088cc;
    i {
      transform: scale(1.2);
    }
  }

  ${MEDIA.DESKTOP`
       left: 10px;
  top: 10px;
     width: 175px;
     height: 175px;
    `};
`;

export const Episode = styled.li`
  display: flex;
  border-bottom: 4px solid #ddd;
  padding: 20px;
  margin: 20px;
  position: relative;

  ${MEDIA.DESKTOP`
  `};

  ${MEDIA.TABLET`
   margin: 0 0 20px 0;
  `};

  ${MEDIA.PHONE`
  flex-direction:column;
   margin: 0 0 20px 0;
  `};
`;
