import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  UWAudioPlayer,
  formatEpisodesToSongObject,
} from 'components/UWAudioPlayer/UWAudioPlayerHowler';
import Item from '../gallery/item/item';
import {
  ImageTrailer,
  Header,
  ArtWork,
  Titles,
  EpisodeList,
  Episode,
  Aside,
} from './productions.css';
import ItemButton from '../gallery/item/item_with_button';
import ContextConsumer from '../../helpers/contextProvider';

const EpisodesMapped = ({
  episodes,
  isPlaying,
  metaData,
  playThis,
  data,
  set,
}) => {
  if (episodes && (Array.isArray(episodes) && episodes.length)) {
    let iteratee = 0;
    const episodesList = episodes.map(function(episode, index) {
      let handleClick = () => {
        if (episode.status === 'released') {
          //playThis(iteratee);
          set({ UWSong: index, UWPlaying: !isPlaying });
          console.log(index);
        }
      };
      let toggleActive = () => {
        document.querySelector('.aside_folds').classList.toggle('active');
      };
      return (
        <Episode
          key={episode.title}
          status={episode.status}
          data-uri={episodes.URI}
        >
          <ItemButton
            className="podcast-hero--list"
            title={episode.title}
            copy={episode.copy.childMarkdownRemark.rawMarkdownBody}
            image={episode.image}
            showButton={episode.status === 'released'}
            onClick={handleClick}
            isPlaying={isPlaying && index === data.UWSong}
          />
          <Titles>
            {episode.references && episode.references.length > 0 && (
              <Aside onClick={toggleActive} className="aside_folds">
                Kildehenvisninger
                <ul className="foldsout">
                  {episode.references.map((item, index) => (
                    <li key={index} item={item}>
                      {episode.references[index]}
                    </li>
                  ))}
                </ul>
              </Aside>
            )}
            <time>{episode.releaseDate}</time>
            <h4>{episode.title}</h4>
            <span
              className="podcast-item__about"
              dangerouslySetInnerHTML={{
                __html: episode.copy.childMarkdownRemark.html,
              }}
            />
          </Titles>
        </Episode>
      );
    });

    return episodesList;
  }
  return <span></span>;
};

export class Productions extends PureComponent {
  state = {
    isPlaying: false,
    metaData: undefined,
  };

  onPlayStateChange = (isPlaying, metaData) => {
    this.setState({ isPlaying: isPlaying, metaData: metaData });
  };

  playThis = songObject => {
    const playFired = new CustomEvent('playSong', {
      detail: formatEpisodesToSongObject({
        title: songObject.title,
        episodes: [songObject],
      }),
    });

    document.dispatchEvent(playFired);
  };

  render() {
    const { data } = this.props;

    const { isPlaying, metaData } = this.state;
    const {
      copy,
      title,
      episodes,
      image,
      longCopy,
      status,
      color,
      background,
      colorText,
    } = data;

    return (
      <ContextConsumer>
        {({ data, set }) => {
          const {
            UWActive,
            UWPlaying,
            ShowUW,
            UWCurrentTime,
            UWTotalTime,
            UWDragging,
            UWLoop,
            UWMute,
            UWSong,
          } = data;

          return (
            <>
              <Header takeover={true} backColor={color} background={background}>
                <ImageTrailer>
                  <ArtWork>
                    <Item
                      className="podcast-hero"
                      title={title}
                      copy={copy}
                      image={image}
                    />
                  </ArtWork>
                  <Titles className="podcast-titles" colorText={colorText}>
                    <h2 className="podcast-title">{title}</h2>
                    <h3 className="podcast-blurb">{copy}</h3>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: longCopy.childMarkdownRemark.html,
                      }}
                    />
                    {status !== 'released' || (
                      <div className="podcast-platforms">
                        <a
                          href="https://open.spotify.com/show/14ErSZoBDZyXRRp9AjpKZ9"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/listen_spotify.png"
                            alt="lyt på Spotify"
                            title="spotify"
                          />
                        </a>
                        <a
                          href="https://podcasts.apple.com/dk/podcast/keder-du-dig-lidt-lige-nu-mens-du-g%C3%A5r-ned-af-denne-gade/id1477993916&ls=1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/images/listen_itunes.png"
                            alt="lyt på Apple Podcasts"
                            title="Apple podcasts"
                          />
                        </a>
                      </div>
                    )}
                  </Titles>
                </ImageTrailer>
              </Header>
              {episodes && (Array.isArray(episodes) && episodes.length) ? (
                <>
                  <EpisodeList>
                    <EpisodesMapped
                      episodes={episodes}
                      isPlaying={UWPlaying}
                      metaData={metaData}
                      playThis={this.playThis}
                      pauseThis={this.pauseThis}
                      data={data}
                      set={set}
                    />
                  </EpisodeList>
                  <UWAudioPlayer
                    audioList={episodes}
                    takeFirst={false}
                    floating={true}
                    title={title}
                  />
                </>
              ) : (
                ''
              )}

            </>
          );
        }}
      </ContextConsumer>
    );
  }
}

Productions.propTypes = {};

export default Productions;
